function lazyLoad(entries, observer) {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      const lazyImage = entry.target;
      lazyImage.src = lazyImage.dataset.src; // Load the actual source
      lazyImage.onload = () => {
        lazyImage.removeAttribute('data-src'); // Remove data-src to prevent further loading
      };
      observer.unobserve(lazyImage); // Stop observing once loaded
    }
  });
}

// Set up the Intersection Observer
const options = {
  root: null, // Use the viewport as the root
  rootMargin: '0px',
  threshold: 0.1, // 10% of the element must be visible
};

export const lazyLoadObserver = new IntersectionObserver(lazyLoad, options);
