function initializeOwlCarousel() {
  $('.owl-carousel').owlCarousel({
    loop: false,
    margin: 0,
    nav: false,
    responsive: {
      0: {
        items: 1
      },
      768: {
        items: 2
      },
    }
  });
}

function reviewsSliderHandler() {
  const owlCarouselScript = document.createElement('script');
  owlCarouselScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/owl.carousel.min.js';

  owlCarouselScript.onload = function () {
    initializeOwlCarousel();
  };

  document.body.appendChild(owlCarouselScript);
}

export function reviewsSliderObserver() {
  const reviewsSlider = document.querySelector('.reviews-slider');

  if (reviewsSlider) {
    const reviewsSliderObserver = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          reviewsSliderHandler();
          reviewsSliderObserver.disconnect();
        }
      });
    });
    reviewsSliderObserver.observe(reviewsSlider);
  }
}
