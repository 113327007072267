import axios from 'axios';
import Cookies from 'js-cookie';

import { popupShow } from '@help/popup.js';
import { validator400, validator400Plus } from '@utils/input_validator.js';
import { maskedTelInputs } from '@utils/masked_tel_inputs.js';
import { inputsCleanCrossHandler } from '@utils/inputs_with_clean_cross.js';
import { gtagReportConversion } from '@integrations/gtag_send_conversion.js';

export async function quoteFormHandler() {
  const quoteForm = document.querySelector('.create-quote-form');
  const inputs = quoteForm.querySelectorAll('.form-input');
  const formData = {
    first_name: '',
    phone: '',
    date: '',
    move_from: '',
    move_to: '',
    email: '',
  };

  if (quoteForm) {
    const submitButton = quoteForm.querySelector('.get-quote-btn');

    maskedTelInputs();
    inputsCleanCrossHandler();
    setDateToTomorrow();

    submitButton.addEventListener('click', () => {
      submitButton.classList.add('disabled');

      submitForm();

      window.setTimeout(() => {
        submitButton.classList.remove('disabled');
      }, 3000);
    })
  }

  function collectFormData() {
    inputs.forEach(input => {
      formData[input.name] = input.value;
    });
  }

  function submitForm() {
    const errorTexts = quoteForm.querySelectorAll('.error-basic-text');
    const apiUrl = '/api/v1/quote/create/';

    collectFormData();

    const csrfToken = Cookies.get('csrftoken');
    axios.defaults.headers.common['X-CSRFToken'] = csrfToken;
    axios
      .post(apiUrl, formData)
      .then(response => {
        const title = 'Thank you!';
        const description = 'Our manager will contact <br>you very soon!';
        popupShow(title, description);
        inputs.forEach(element => {
          element.value = '';
          element.nextElementSibling.classList.remove('has-content');
        });
        setDateToTomorrow();

        dataLayer.push({ event: 'order_complete' }); // for google tag manager
        gtagReportConversion();

        // uet_report_conversion(); // for Bing Ads
      })
      .catch(error => {
        if (400 < error.response.status) {
          validator400Plus(inputs, errorTexts);
        } else {
          validator400(
            error.response.data.errors[0].state,
            inputs,
            errorTexts,
            false,
            true
          );
        }
      });
  }

  function setDateToTomorrow() {
    const today = new Date();
    const tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000);
    const tomorrowFormatted = `${tomorrow.getMonth() + 1}-${tomorrow.getDate()}-${tomorrow.getFullYear()}`;
    quoteForm.querySelector('.date-input').value = tomorrowFormatted;
  }
}
