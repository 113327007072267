export function hideAllErrors(inputs, errorTexts, errorTextClass) {
  errorTexts.forEach(element => {
    element.classList.add(errorTextClass);
  });
  inputs.forEach(element => {
    element.classList.remove('is-required');
  });
  const labels = document.querySelectorAll('label');
  labels.forEach(element => {
    element.classList.remove(errorTextClass);
  });
}

function showError(key, element, inputs, errors, errorTexts, haveLabels, isTransparent) {
  const inputBlock = element.parentNode;
  const errorText = inputBlock.previousElementSibling;
  let errorTextClass = 'hidden'
  if (isTransparent) {
    errorTextClass = 'transparent'
  }

  if (haveLabels) {
    const label = inputBlock.parentNode.querySelector('label');
    label.classList.add(errorTextClass);
  }

  errorText.innerHTML = errors[key][0].message;
  errorText.classList.remove(errorTextClass);

  element.classList.add('is-required');
  element.addEventListener('input', () => {
    hideAllErrors(inputs, errorTexts, errorTextClass)
  })
}

export function validator400(errors, inputs, errorTexts, haveLabels = false, isTransparent = false) {
  /* make red borders for required inputs */
  Object.keys(errors).forEach(key => {
    const camelToSnakeCase = key.replace(
      /[A-Z]/g,
      letter => `_${letter.toLowerCase()}`
    );
    inputs.forEach(element => {
      if (camelToSnakeCase === element.name) {
        showError(key, element, inputs, errors, errorTexts, haveLabels, isTransparent);
      }
    });
  });
}

export function validator400Plus(inputs, errorTexts) {
  const input = inputs[0];
  const errorText = errorTexts[0];

  errorText.innerHTML = 'Permission denied.';
  errorText.classList.remove('hidden');

  inputs.forEach(element => {
    element.classList.add('is-required');
  })

  input.addEventListener('input', () => {
    hideAllErrors(inputs, errorTexts)
  })
}
