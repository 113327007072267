import './public-path';

// import { install } from '@/install';

import './styles/index.sass';

import { reviewsSliderObserver } from '@tools/reviews-slider.js';
import { accordionListener } from '@tools/click_accordion.js';
import { headerHandler } from '@help/header_handler.js';
import { footerObserver } from '@help/footer_handler.js';
import { quoteFormHandler } from '@forms/create_quote.js';
import { lazyLoadObserver } from '@tools/lazy_load.js';

// app.$mount('#app')

document.addEventListener('DOMContentLoaded', () => {
  headerHandler();
  footerObserver();
  accordionListener(); // can observe?
  reviewsSliderObserver();

  // move to func
  if (document.querySelector('.create-quote-form')) {
    quoteFormHandler();
  }

  // move to func
  const inputs = document.querySelectorAll('.date-input');
  inputs.forEach(e => {
    flatpickr(e, { dateFormat: 'm-d-Y', minDate: 'today' });
  })

  // move to func
  const lazyImages = document.querySelectorAll('[data-src]');
  lazyImages.forEach(img => {
    lazyLoadObserver.observe(img);
  });
})
