export function inputsCleanCrossHandler() {
  const inputs = document.querySelectorAll('.form-input');
  inputs.forEach(element => {
    const elementCross = element.nextElementSibling;
    const isTelephone = element.type == 'tel';
    element.addEventListener('input', () => {
      let value = isTelephone ? element.value.replace(/\D/g, '') : element.value;

      if (value.length >= 3) {
        elementCross.classList.add('has-content');
        elementCross.addEventListener('click', () => {
          element.value = '';
          elementCross.classList.remove('has-content');
        })
      } else {
        elementCross.classList.remove('has-content');
      }
    })
  })
}
