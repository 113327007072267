export function popupShow(titleText, descriptionText) {
  const body = document.getElementById('body');
  const layout = document.querySelector('.layout');
  const popup = layout.querySelector('.popup');
  const title = popup.querySelector('.title');
  const description = popup.querySelector('.description');

  title.innerHTML = titleText || '';
  description.innerHTML = descriptionText || '';

  layout.classList.add('layout--showed');

  body.classList.add('blocked');

  window.setTimeout(() => {
    layout.classList.remove('layout--showed');
    body.classList.remove('blocked');
  }, 3500)
}
