export function clickAccordion(event) {
  const faqItem = event.target.closest('.faq-item');
  faqItem.classList.toggle('active');
}

export function accordionListener() {
  const links = document.querySelectorAll('.accordion-header');
  links.forEach(link => {
    link.addEventListener('click', event => {
      clickAccordion(event);
    })
  })
}
