function getCellItem() {
  const cellItem = document.createElement('div');
  cellItem.classList.add('g-cell', 'g-cols--12');
  return cellItem;
}

function getDefaultCellItem(menuTitle) {
  const defaltCellItem = getCellItem();
  const titleItem = document.createElement('span');
  titleItem.classList.add('title');
  titleItem.textContent = menuTitle;
  defaltCellItem.appendChild(titleItem);
  return defaltCellItem;
}

function footerHandler() {
  const body = document.querySelector('body');
  const footerMenus = body.querySelectorAll('.footer .links .title');
  const linksPopup = body.querySelector('.footer .links-popup');
  const popupContent = linksPopup.querySelector('.content');
  const popupMenuLinksBlock = popupContent.querySelector('.g-row');
  const arrowStyle = window.getComputedStyle(body.querySelector('.footer .button-arrow'));

  if (arrowStyle.display === 'flex') {
    const menuList = JSON.parse(window.menuList || '{}');

    footerMenus.forEach(menu => {
      menu.addEventListener('click', () => {
        linksPopup.classList.toggle('modal-active');
        body.classList.add('blocked');

        const menuTitle = menu.textContent;
        const defaultMenuValue = getDefaultCellItem(menuTitle);
        popupMenuLinksBlock.appendChild(defaultMenuValue);

        const links = menuList[menuTitle.toLocaleLowerCase()].links;
        links.forEach(link => {
          const cellItem = getCellItem();
          const linkBlock = document.createElement('div');
          const linkItem = document.createElement('a');

          linkBlock.classList.add('link-block');
          linkItem.classList.add('link');
          if (window.location.pathname === link.path) {
            linkItem.classList.add('current');
          } else {
            linkItem.href = link.path;
            linkItem.classList.add('active');
          }
          linkItem.textContent = link.title;

          popupMenuLinksBlock.appendChild(cellItem);
          cellItem.appendChild(linkBlock);
          linkBlock.appendChild(linkItem);
        })
      })
    })
  }

  document.addEventListener('click', event => {
    const isMenuLinkClicked = Array.from(footerMenus).includes(event.target);
    const isClickInsidePopupContent = popupContent.contains(event.target);
    if (!isMenuLinkClicked && !isClickInsidePopupContent) {
      popupMenuLinksBlock.innerHTML = '';
      linksPopup.classList.remove('modal-active');
      body.classList.remove('blocked');
    }
  })
}

export function footerObserver() {
  const footerElement = document.querySelector('.footer');

  if (footerElement) {
    const footerObserver = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          footerHandler();
          observer.disconnect();
        }
      });
    });
    footerObserver.observe(footerElement);
  }
}