function toggleDropdown(block, dropdown, caret = null) {
  const activeDropdown = document.querySelector('.dropdown.is-active') || null;
  if (activeDropdown && activeDropdown !== dropdown) {
    activeDropdown.classList.remove('is-active');
    const activeDropdownCaret = activeDropdown.parentElement.querySelector('.caret');
    if (activeDropdownCaret) {
      activeDropdownCaret.classList.remove('is-active');
    }
  }

  block.classList.toggle('is-active');
  dropdown.classList.toggle('is-active');

  if (caret) {
    caret.classList.toggle('is-active');
  }
}

function toggleAccordion(accordionBlock, accordion, burgerMenu) {
  const caret = accordionBlock.querySelector('.caret');
  const activeAccordions = burgerMenu.querySelectorAll('.accordion.is-active') || [];

  activeAccordions.forEach(element => {
    if (element !== accordion) {
      element.classList.remove('is-active');
      element.parentElement.querySelector('.caret').classList.remove('is-active');
    }
  });

  accordionBlock.classList.toggle('is-active');
  accordion.classList.toggle('is-active');
  caret.classList.toggle('is-active');
}

export function headerHandler() {
  const burgerMenu = document.querySelector('.burger-menu');
  if (burgerMenu) {
    const burgerDropdownHead = burgerMenu.querySelector('.dropdown-head');
    const burgerDropdown = burgerMenu.querySelector('.dropdown');

    const burgerServices = burgerMenu.querySelector('.menu-services');
    const burgerServicesAccordionHead = burgerServices.querySelector('.accordion-head');
    const burgerServicesAccordion = burgerServices.querySelector('.accordion');

    const burgerLocations = burgerMenu.querySelector('.menu-locations');
    const burgerLocationsAccordionHead = burgerLocations.querySelector('.accordion-head');
    const burgerLocationsAccordion = burgerLocations.querySelector('.accordion');

    burgerDropdownHead.addEventListener('click', () => {
      toggleDropdown(burgerMenu, burgerDropdown);
    })

    burgerServicesAccordionHead.addEventListener('click', () => {
      toggleAccordion(burgerServices, burgerServicesAccordion, burgerMenu)
    })

    burgerLocationsAccordionHead.addEventListener('click', () => {
      toggleAccordion(burgerLocations, burgerLocationsAccordion, burgerMenu)
    })

    document.addEventListener('click', event => {
      const isBurgerOpen = burgerMenu.classList.contains('is-active');
      const isClickInsideBurger = burgerMenu.contains(event.target);
      if (isBurgerOpen && !isClickInsideBurger) {
        burgerMenu.classList.remove('is-active');
        burgerDropdown.classList.remove('is-active');
      }
    })
  }

  const headerMenus = document.querySelector('.header-menus');
  if (headerMenus) {
    const menuServices = headerMenus.querySelector('.menu-services');
    const menuServicesDropdownHead = menuServices.querySelector('.dropdown-head');
    const menuServicesCaret = menuServicesDropdownHead.querySelector('.caret');
    const menuServicesDropdown = menuServices.querySelector('.dropdown');

    const menuLocations = headerMenus.querySelector('.menu-locations');
    const menuLocationsDropdownHead = menuLocations.querySelector('.dropdown-head');
    const menuLocationsCaret = menuLocationsDropdownHead.querySelector('.caret');
    const menuLocationsDropdown = menuLocations.querySelector('.dropdown');

    menuServicesDropdownHead.addEventListener('click', () => {
      toggleDropdown(menuServices, menuServicesDropdown, menuServicesCaret);
    })

    menuLocationsDropdownHead.addEventListener('click', () => {
      toggleDropdown(menuLocations, menuLocationsDropdown, menuLocationsCaret);
    })

    document.addEventListener('click', event => {
      const activeDropdown = headerMenus.querySelector('.dropdown.is-active') || null;
      if (activeDropdown) {
        const isDropdownHeadClicked = Array.from(headerMenus.querySelectorAll('.dropdown-head')).includes(event.target);
        const isClickInActiveDropdown = activeDropdown.contains(event.target);

        if (!isDropdownHeadClicked && !isClickInActiveDropdown) {
          activeDropdown.classList.remove('is-active');
          activeDropdown.parentElement.querySelector('.caret').classList.remove('is-active');
        }
      }
    })
  }
}
