// Event snippet for form submission conversion page
// In your html page, add the snippet and call gtag_report_conversion
// when someone clicks on the chosen link or button.
export function gtagReportConversion() {
  var callback = function () {
    // if (typeof(url) != 'undefined') {
    // window.location = url;
    // }
  };
  gtag('event', 'conversion', {
      'send_to': 'AW-11285757686/3rEFCICJ6c0YEPb9u4Uq',
      'event_callback': callback,
    }
  );
  return false;
}
